\<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            label="감지기NO"
            name="gasDetectorNo"
            v-model="searchParam.gasDetectorNo">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            label="감지대상"
            name="sensingObject"
            v-model="searchParam.sensingObject">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            label="설치장소"
            name="installLocation"
            v-model="searchParam.installLocation">
          </c-text>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 관련공정 -->
          <c-process
            label="LBL0001705"
            multiple="single"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 관리부서 -->
          <c-dept
            type="search"
            label="LBL0001721"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <!-- 가스누출감지 경보기 설치 목록 -->
        <c-table
          ref="gasleak"
          title="LBL0010532"
          tableId="gasleak"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          rowKey="psiGasAlarmId"
          :columnSetting="false"
          :expandAll="true"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <!-- 등록 -->
              <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="equipmentPopup" />
              <!-- 검색 -->
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'gas-leak',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            // 사업장
            align: 'center',
            style: 'width:100px',
            sortable: true,
            fix: true,
          },
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'Rev.',
            align: 'center',
            style: 'width:50px',
            sortable: true,
            fix: true,
          },
          {
            name: 'gasDetectorNo',
            field: 'gasDetectorNo',
            // 가스감지기NO
            label: 'LBL0010533',
            align: 'center',
            style: 'width:120px',
            type: 'link',
            sortable: true,
            fix: true,
          },
          {
            name: 'sensingObject',
            field: 'sensingObject',
            // 감지대상
            label: 'LBL0010534',
            align: 'center',
            style: 'width:120px',
            sortable: true,
            fix: true,
          },
          {
            name: 'installLocation',
            field: 'installLocation',
            // 설치지역
            label: 'LBL0010501',
            align: 'center',
            style: 'width:120px',
            sortable: true,
            fix: true,
          },
          {
            name: 'operationTime',
            field: 'operationTime',
            // 작동시간
            label: 'LBL0010535',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'detectMethod',
            field: 'detectMethod',
            // 측정방식
            label: 'LBL0010536',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            // 경보설정값
            label: 'LBL0010537',
            align: 'center',
            child: [
              {
                name: 'alarmHighValue',
                field: 'alarmHighValue',
                label: 'high',
                align: 'center',
                style: 'width:90px',
                sortable: true,
              },
              {
                name: 'alarmHighhighValue',
                field: 'alarmHighhighValue',
                label: 'high high',
                align: 'center',
                style: 'width:90px',
                sortable: true,
              },
            ]
          },
          {
            name: 'alarmSettingLocation',
            field: 'alarmSettingLocation',
            // 경보기설치위치
            label: 'LBL0010538',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'precisionVal',
            field: 'precisionVal',
            // 정밀도
            label: 'LBL0010539',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'measureInfo',
            field: 'measureInfo',
            // 조치내용
            label: 'LBL0010540',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'management',
            field: 'management',
            // 유지관리
            label: 'LBL0010541',
            style: 'width:200px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'remark',
            field: 'remark',
            // 비고
            label: 'LBLREMARK',
            style: 'width:200px',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        gasDetectorNo: '',
        sensingObject: '',
        installLocation: '',
        processCd: '',
        deptCd: '',
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.psi.lbe.gasalarm.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.equipmentPopup(row);
    },
    equipmentPopup(result) {
      this.popupOptions.target = () => import(`${"./gasLeakDetectionAlarmInfoDetail.vue"}`);
      this.popupOptions.title = 'LBL0010542'; // 가스누출감지 경보기 설치 상세
      this.popupOptions.param = {
        psiGasAlarmId: result ? result.psiGasAlarmId : '',
      };
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
